import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { makeView } from 'uikit/helpers/View'

const NextLink = ({ href, className, children }) => (
  <Link href={href} className={className}>
    {children}
  </Link>
)

export default makeView(styled(NextLink)`
  display: block;
  &:hover {
    text-decoration: none;
  }
`)
