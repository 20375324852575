import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Container, ContentBox } from '../components/MarkupHelpers'
import { make as DashboardHero } from '../reason/dashboard/DashboardHero.bs'

import NotificationWidget from '../modules/Notification/components/NotificationWidgetWithData.bs'
import ClientWidget from '../components/ClientWidget/ClientWidgetWithData.bs'
import { mediaBreakpointUp } from '../styles/media'

const NotificationWidgetContentBox = ({ children }) => (
  <ContentBox paddingFull>{children}</ContentBox>
)

const StyledContainer = styled(Container)`
  padding-top: 15px;
  ${mediaBreakpointUp('md')`
    padding-top: 30px;
    padding-bottom: 30px;
  `};
  ${mediaBreakpointUp('lg')`
    padding-top: 40px;
    padding-bottom: 40px;
  `};
  padding-bottom: 15px;
`

NotificationWidgetContentBox.propTypes = {
  children: PropTypes.node,
}

function Dashboard() {
  return (
    <div>
      <DashboardHero />
      <StyledContainer>
        <ContentBox paddingFull>
          <NotificationWidget limit={5} />
        </ContentBox>
        <ClientWidget />
      </StyledContainer>
    </div>
  )
}

export default Dashboard
