import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { fontWeight } from 'uikit/styles/theme'
import { themeGet } from 'styled-system'
import View from 'uikit/helpers/View'
import BaseTouchable from 'uikit/helpers/TouchableView'
import PrimaryButton from 'uikit/atoms/Buttons/PrimaryButton'
import Text from 'uikit/helpers/Text'
import List from 'uikit/atoms/List'
import Heading from 'uikit/helpers/Heading'
import { dialog as Dialog } from 'uikit/reason/molecules/Dialog/Dialog__Jsx3.bs'
import { useWindowSize } from 'uikit/hooks/useWindowSize'
import { PrimaryButton as PrimaryLinkButton } from '../next/ButtonLink'
import ViewStack from '../../components/ViewStack'
import clientBuying from './client-buying.svg'
import clientSelling from './client-selling.svg'
import broadcastMegaphone from './broadcast-megaphone.svg'
import { BottomShadow, TouchableView, ViewLink } from './styles'
import { clickOutside as ClickOutside } from './DashboardHeroComponents.bs'

// eslint-disable-next-line react/prop-types
const IconView = ({ src, alt }) => (
  <View as="img" height={['52px', '52px', '62px']} src={src} alt={alt} />
)

// eslint-disable-next-line react/prop-types
const ExpandedContentSection = ({ link, title, listItems, cta }) => {
  return (
    <View
      flexDirection="column"
      alignItems="center"
      width="50%"
      p={['30px 50px', '30px 50px', '30px 38px 30px 30px', '30px 60px 30px 40px']}
    >
      <PrimaryLinkButton href={link} size="large" px="30px">
        {cta}
      </PrimaryLinkButton>
      <Heading as="h4" mt="20px" mb={0} textAlign="center" fontSize="14px" lineHeight={1.5}>
        {title}
      </Heading>
      <List
        items={listItems}
        fontSize="14px"
        color={themeGet('colors.chicago')}
        itemSpacing="10px"
        type="ok-circled-alt"
        iconSize={20}
        mt="20px"
        iconMarginTop="2px"
      />
    </View>
  )
}

const ExpandedContentBuy = ({ addPrivateBuyerLink, addBroadcastBuyerLink }) => {
  return (
    <>
      <ExpandedContentSection
        link={addPrivateBuyerLink}
        cta="Add buyer quietly"
        title="Connect only with agents who have a matching seller, without emailing your TAN chapter."
        listItems={[
          'Common buyer needs',
          'Buyers early in the home hunt',
          'Buyers who have NOT exhausted the MLS',
        ]}
      />

      <View flexDirection="row" width="1px" bg="white">
        <View width="100%" m="30px 0" bg="gallery" />
      </View>
      <ExpandedContentSection
        link={addBroadcastBuyerLink}
        cta="Broadcast a buyer or rental need"
        title="Email a buyer or rental need to your entire TAN network. You'll also get matches."
        listItems={[
          'Unique needs',
          'Motivated, qualified and realistic',
          "Already seen what's on the MLS",
        ]}
      />
    </>
  )
}

const ExpandedContentSell = ({ addPrivateSellerLink, addBroadcastSellerLink }) => {
  return (
    <>
      <ExpandedContentSection
        link={addPrivateSellerLink}
        cta="Add seller quietly"
        title="Find or connect with only agents who have a matching buyer, without emailing your TAN chapter."
        listItems={[
          'Too early to market a property (no photos, poor condition, etc.)',
          'Quietly gauge buyer demand and win listings',
          'Seller demands extra privacy',
        ]}
      />
      <View flexDirection="row" width="1px" bg="white">
        <View width="100%" m="30px 0" bg="gallery" />
      </View>
      <ExpandedContentSection
        link={addBroadcastSellerLink}
        cta="Broadcast a property"
        title="Email a property or rental to your entire TAN network. You'll also get matches."
        listItems={['Reach all local top agents', 'Test pricing']}
      />
    </>
  )
}

const sectionPadding = ['25px 20px 20px 20px', '25px 20px 20px 20px', '30px', '40px 40px 35px 40px']

const ExpandedHolder = ({
  roleState,
  setRoleState,
  addPrivateSellerLink,
  addPrivateBuyerLink,
  addBroadcastBuyerLink,
  addBroadcastSellerLink,
}) => {
  const wrapActive = (src, alt) => (
    <View
      borderRadius="5px 5px 0 0"
      justifyContent="center"
      width="50%"
      p={sectionPadding}
      bg={themeGet('colors.white')}
      style={{ zIndex: 1 }}
    >
      <IconView src={src} alt={alt} />
    </View>
  )
  const wrapInactive = (src, alt, roleStateName) => (
    <BaseTouchable
      onClick={() => setRoleState(roleStateName)}
      justifyContent="center"
      width="50%"
      p={sectionPadding}
      bg={themeGet('colors.white')}
      position="relative" // So that BottomShadow attaches correctly
      opacity={0.5}
      borderRadius="5px 5px 0 0"
    >
      <IconView src={src} alt={alt} />
      <BottomShadow />
    </BaseTouchable>
  )

  const wrapBuying = roleState === 'sell' ? wrapInactive : wrapActive
  const wrapSelling = roleState === 'sell' ? wrapActive : wrapInactive

  return (
    <View width="100%" flexDirection="column" alignItems="center" borderRadius="5px">
      <ClickOutside
        onClick={() => {
          setRoleState(null)
        }}
      >
        <ViewStack space="16px" width="100%" flexDirection="row">
          {wrapBuying(clientBuying, 'Keys', 'buy')}
          {wrapSelling(clientSelling, 'House', 'sell')}
        </ViewStack>
        <View width="100%" flexDirection="row" bg={themeGet('colors.white')} style={{ zIndex: 1 }}>
          {roleState === 'sell' ? (
            <ExpandedContentSell
              addBroadcastSellerLink={addBroadcastSellerLink}
              addPrivateSellerLink={addPrivateSellerLink}
            />
          ) : (
            <ExpandedContentBuy
              addPrivateBuyerLink={addPrivateBuyerLink}
              addBroadcastBuyerLink={addBroadcastBuyerLink}
            />
          )}
        </View>
      </ClickOutside>
    </View>
  )
}

// eslint-disable-next-line react/prop-types
const ModalContent = ({ heading, img, alt, sections }) => (
  <View alignItems="center" flexDirection="column">
    <IconView src={img} alt={alt} />
    <Heading as="h4" mb={0} mt="20px" fontSize="22px">
      {heading}
    </Heading>
    {sections.map(({ link, cta, text }, i) => {
      return (
        <React.Fragment key={link}>
          <PrimaryLinkButton href={link} size="large" mt="25px">
            {cta}
          </PrimaryLinkButton>
          <Heading
            as="h4"
            mt="20px"
            mb={0}
            textAlign="center"
            fontSize="14px"
            lineHeight={1.5}
            width="90%"
            color={themeGet('colors.mineShaft')}
          >
            {text}
          </Heading>
          {i < sections.length - 1 ? (
            <View height="1px" bg={themeGet('colors.gallery')} width="100%" mt="25px" />
          ) : null}
        </React.Fragment>
      )
    })}
  </View>
)

// eslint-disable-next-line react/prop-types
const ModalContentBuy = ({ addPrivateBuyerLink, addBroadcastBuyerLink }) => {
  return (
    <ModalContent
      heading="My client needs to buy or rent"
      img={clientBuying}
      sections={[
        {
          cta: 'Add buyer quietly',
          text: 'Connect only with agents who have a matching seller, without emailing your TAN chapter.',
          link: addPrivateBuyerLink,
        },
        {
          cta: 'Broadcast a buyer or rental need',
          text: "Email a buyer or rental need to your entire TAN network. You'll also get matches.",
          link: addBroadcastBuyerLink,
        },
      ]}
    />
  )
}

const ModalContentSell = ({ addPrivateSellerLink, addBroadcastSellerLink }) => {
  return (
    <ModalContent
      heading="My client is selling or renting"
      img={clientSelling}
      sections={[
        {
          cta: 'Add seller quietly',
          text: 'Find or connect with only agents who have a matching buyer, without emailing your TAN chapter.',
          link: addPrivateSellerLink,
        },
        {
          cta: 'Broadcast a property',
          text: "Email a property or rental to your entire TAN network. You'll also get matches.",
          link: addBroadcastSellerLink,
        },
      ]}
    />
  )
}

const ClosedSection = ({ onClick, img, listItems, cta, ...props }) => {
  return (
    <TouchableView
      {...props}
      flexDirection="column"
      alignItems="center"
      width={['100%', '100%', '50%']}
      background={themeGet('colors.white')}
      p={sectionPadding}
      borderRadius="5px"
      onClick={onClick}
    >
      <IconView src={img} alt={cta} />
      <Heading as="h4" mb={0} mt="20px" fontSize="22px" display={['block', 'block', 'none']}>
        {cta}
      </Heading>
      <View mt="30px" mb="5px" display={['none', 'none', 'block']}>
        <PrimaryButton renderAs="div" size="huge" px="30px" onClick={onClick}>
          {cta}
        </PrimaryButton>
        <List
          items={listItems}
          fontSize="14px"
          color={themeGet('colors.chicago')}
          itemSpacing="10px"
          type="ok-circled-alt"
          iconSize={20}
          mt="20px"
          iconMarginTop="2px"
        />
      </View>
    </TouchableView>
  )
}

const DashboardHero = props => {
  const node = useRef()
  const [roleState, setRoleState] = useState(null)
  const expandHandler = (e, role) => {
    e.nativeEvent.stopImmediatePropagation()
    setRoleState(role)
  }
  const windowSize = useWindowSize()
  const isMobileView = windowSize.width < 768
  return (
    <View flexDirection="column" alignItems="center">
      <Dialog ariaLabel="" isOpen={roleState && isMobileView} onDismiss={() => setRoleState(null)}>
        {roleState === 'sell' ? <ModalContentSell {...props} /> : <ModalContentBuy {...props} />}
      </Dialog>
      <View width="100%" maxWidth="920px" m="0 auto" ref={node}>
        {roleState && !isMobileView ? (
          <ExpandedHolder setRoleState={setRoleState} roleState={roleState} {...props} />
        ) : (
          <ViewStack flexDirection={{ xs: 'column', md: 'row' }} space="16px">
            <ClosedSection
              img={clientBuying}
              cta="My client needs to buy or rent"
              onClick={e => expandHandler(e, 'buy')}
              listItems={['Broadcast a buyer or rental need', 'Add a buyer quietly']}
            />
            <ClosedSection
              img={clientSelling}
              cta="My client is selling or renting"
              onClick={e => expandHandler(e, 'sell')}
              listItems={['Broadcast a property or available rental', 'Add a seller quietly']}
            />
          </ViewStack>
        )}
      </View>

      {(!roleState || (roleState && isMobileView)) && (
        // TODO focus style  & use real button component
        <ViewLink
          href={props.addBroadcastMessageLink}
          flexDirection="row"
          bg={themeGet('colors.white')}
          hoverBg={themeGet('colors.alabasterAlt')}
          maxWidth="768px"
          p={['24px']}
          mt={['16px', '16px', '16px', '40px']}
          borderRadius="5px"
          width={['100%', '100%', '66%', '50%']}
        >
          <View
            as="img"
            height={['52px', '52px', '62px']}
            src={broadcastMegaphone}
            alt=""
            flexShrink={0}
            mr="24px"
            mt="5px"
          />
          <View>
            <Heading
              as="h3"
              lineHeight={1}
              mb="10px"
              fontSize="22px"
              fontWeight={fontWeight.regular}
            >
              Broadcast a message
            </Heading>
            <Text fontSize="14px" color={themeGet('colors.chicago')}>
              Start here to connect about service provider recommendations, referrals to agents in
              other markets, industry discussion and other topics.
            </Text>
          </View>
        </ViewLink>
      )}
    </View>
  )
}

DashboardHero.propTypes = {
  addPrivateSellerLink: PropTypes.string.isRequired,
  addPrivateBuyerLink: PropTypes.string.isRequired,
  addBroadcastBuyerLink: PropTypes.string.isRequired,
  addBroadcastSellerLink: PropTypes.string.isRequired,
  addBroadcastMessageLink: PropTypes.string.isRequired,
}

export default DashboardHero
