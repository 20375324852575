// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles");
var JsxRuntime = require("react/jsx-runtime");

function DashboardHeroComponents$KeyboardListener(props) {
  var keyCode = props.keyCode;
  var onPress = props.onPress;
  var handler = React.useCallback((function (e) {
          if (e.key === keyCode) {
            Curry._1(onPress, undefined);
            return ;
          }
          
        }), [onPress]);
  React.useEffect((function (param) {
          window.addEventListener("keydown", handler);
          return (function (param) {
                    window.removeEventListener("keydown", handler);
                  });
        }), [handler]);
  return null;
}

var KeyboardListener = {
  make: DashboardHeroComponents$KeyboardListener
};

var make = Styles.ClickOutsideOverlay;

var ClickOutsideOverlay = {
  make: make
};

function DashboardHeroComponents$ClickOutside(props) {
  var onClick = props.onClick;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DashboardHeroComponents$KeyboardListener, {
                      onPress: (function (param) {
                          Curry._1(onClick, undefined);
                        }),
                      keyCode: "Escape"
                    }),
                JsxRuntime.jsx(make, {
                      role: "button",
                      tabIndex: 0,
                      onClick: (function (param) {
                          Curry._1(onClick, undefined);
                        })
                    }),
                props.children
              ]
            });
}

var ClickOutside = {
  ClickOutsideOverlay: ClickOutsideOverlay,
  make: DashboardHeroComponents$ClickOutside
};

var clickOutside = DashboardHeroComponents$ClickOutside;

exports.KeyboardListener = KeyboardListener;
exports.ClickOutside = ClickOutside;
exports.clickOutside = clickOutside;
/* make Not a pure module */
