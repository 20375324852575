// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Loader = require("../../uikit/reason/atoms/Loader/Loader.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var ApolloHooks = require("reason-apollo-hooks/src/ApolloHooks.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Atoms = require("../../reason/next/Next__Atoms.bs.js");
var Heading__Jsx3 = require("../../uikit/reason/helpers/Heading__Jsx3.bs.js");
var ErrorComponent = require("../../reason/common/Error/ErrorComponent.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../reason/common/Theme/Theme__Color__Vars.bs.js");
var MarkupHelpers = require("components/MarkupHelpers");

function ClientWidgetWithData$AddContacts(props) {
  var url = props.url;
  var noun = props.noun;
  var count = props.count;
  var nounPlural = noun + "s";
  var activeNounForm = count === 1 ? noun : nounPlural;
  if (count === 0) {
    return JsxRuntime.jsxs("span", {
                children: [
                  "You have no active " + nounPlural + ". ",
                  JsxRuntime.jsx(Next__Atoms.Link.make, {
                        href: url,
                        children: "Add your " + noun + " to get matches"
                      })
                ]
              });
  } else if (count < 3) {
    return JsxRuntime.jsxs("span", {
                children: [
                  "You have " + count.toString() + " active " + activeNounForm + ". ",
                  JsxRuntime.jsx(Next__Atoms.Link.make, {
                        href: url,
                        children: "Add more " + nounPlural + " to get more matches"
                      })
                ]
              });
  } else {
    return JsxRuntime.jsx("span", {
                children: "You have " + count.toString() + " active " + nounPlural + "."
              });
  }
}

var AddContacts = {
  make: ClientWidgetWithData$AddContacts
};

var ppx_printed_query = "query ClientStats  {\nclientStatsForCurrentUser  {\nagentId  \nbuyers  \nsellers  \n}\n\n}\n";

function parse(value) {
  var value$1 = value["clientStatsForCurrentUser"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = Js_json.decodeObject(value$1);
    var tmp$1;
    if (value$2 !== undefined) {
      var value$3 = Js_dict.get(value$2, "agentId");
      var field_agentId = value$3 !== undefined ? value$3 : Js_exn.raiseError("Unexpected GraphQL query response");
      var value$4 = Js_dict.get(value$2, "buyers");
      var field_buyers = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
      var value$5 = Js_dict.get(value$2, "sellers");
      var field_sellers = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
      tmp$1 = {
        agentId: field_agentId,
        buyers: field_buyers,
        sellers: field_sellers
      };
    } else {
      tmp$1 = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    tmp = tmp$1;
  }
  return {
          clientStatsForCurrentUser: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ClientStats = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function ClientWidgetWithData$ClientWidget(props) {
  return JsxRuntime.jsxs(MarkupHelpers.ContentBox, {
              paddingFull: true,
              children: [
                JsxRuntime.jsx(Heading__Jsx3.make, {
                      as_: "h3",
                      mb: {
                        NAME: "px",
                        VAL: 15
                      },
                      fontWeight: "medium",
                      children: "My Clients"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(View__Jsx3.make, {
                              py: {
                                NAME: "px",
                                VAL: 15
                              },
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "Home",
                                      size: 30,
                                      color: Theme__Color__Vars.stTropaz
                                    }),
                                JsxRuntime.jsx(View__Jsx3.make, {
                                      pl: {
                                        NAME: "px",
                                        VAL: 15
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(ClientWidgetWithData$AddContacts, {
                                                count: props.sellers,
                                                noun: "seller",
                                                url: "/sellers/new"
                                              }))
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(View__Jsx3.make, {
                              py: {
                                NAME: "px",
                                VAL: 15
                              },
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "People",
                                      size: 30,
                                      color: Theme__Color__Vars.stTropaz
                                    }),
                                JsxRuntime.jsx(View__Jsx3.make, {
                                      pl: {
                                        NAME: "px",
                                        VAL: 15
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(ClientWidgetWithData$AddContacts, {
                                                count: props.buyers,
                                                noun: "buyer",
                                                url: "/private_buyers/new"
                                              }))
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var ClientWidget = {
  make: ClientWidgetWithData$ClientWidget
};

function ClientWidgetWithData$ClientWidgetWithData(props) {
  var match = ApolloHooks.useQuery(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var result = match[0];
  if (typeof result !== "object") {
    if (result === "Loading") {
      return JsxRuntime.jsx(Loader.make, {});
    } else {
      return JsxRuntime.jsx(ErrorComponent.make, {
                  message: "Error loading your data..."
                });
    }
  }
  if (result.TAG !== "Data") {
    return JsxRuntime.jsx(ErrorComponent.make, {
                message: "Error loading your data..."
              });
  }
  var match$1 = result._0.clientStatsForCurrentUser;
  if (match$1 === undefined) {
    return JsxRuntime.jsx(ErrorComponent.make, {
                message: "An error ocurred while fetching your data"
              });
  }
  var buyers = match$1.buyers;
  if (buyers === undefined) {
    return JsxRuntime.jsx(ErrorComponent.make, {
                message: "An error ocurred while fetching your data"
              });
  }
  var sellers = match$1.sellers;
  if (sellers !== undefined) {
    return JsxRuntime.jsx(ClientWidgetWithData$ClientWidget, {
                sellers: sellers,
                buyers: buyers
              });
  } else {
    return JsxRuntime.jsx(ErrorComponent.make, {
                message: "An error ocurred while fetching your data"
              });
  }
}

var ClientWidgetWithData = {
  make: ClientWidgetWithData$ClientWidgetWithData
};

var $$default = ClientWidgetWithData$ClientWidgetWithData;

exports.AddContacts = AddContacts;
exports.ClientStats = ClientStats;
exports.ClientWidget = ClientWidget;
exports.ClientWidgetWithData = ClientWidgetWithData;
exports.default = $$default;
exports.__esModule = true;
/* Loader Not a pure module */
