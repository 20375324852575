import styled from 'styled-components'
import { makeView } from './View'

const TouchableWrapper = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-align: inherit;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    background: ${props => props.hoverBg};
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

const TouchableView = makeView(TouchableWrapper)

TouchableView.displayName = 'TouchableView'

export default TouchableView
