// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Responsive = require("../../uikit/reason/helpers/Responsive.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var CurrentUser = require("../common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Heading__Jsx3 = require("../../uikit/reason/helpers/Heading__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var DashboardHeroJsx = require("./DashboardHero.jsx").default;
var Colors = require("uikit/styles/colors");

var make = DashboardHeroJsx;

var DashboardHeroUI = {
  make: make
};

function DashboardHero(props) {
  var user = CurrentUser.useCurrentUser();
  var areaName = Core__Option.getOr(Core__Option.flatMap(user.chapter, (function (a) {
              return a.displayName;
            })), "your area");
  return JsxRuntime.jsxs(View__Jsx3.make, {
              p: Responsive.make({
                    NAME: "px",
                    VAL: 15
                  }, undefined, {
                    NAME: "px",
                    VAL: 30
                  }, undefined, undefined, undefined, undefined),
              background: Colors.gallery,
              children: [
                JsxRuntime.jsx(Heading__Jsx3.make, {
                      as_: "h1",
                      mb: {
                        NAME: "px",
                        VAL: 30
                      },
                      textAlign: "center",
                      children: "Connect with " + (areaName + "'s top 10% agents")
                    }),
                JsxRuntime.jsx(make, {
                      addPrivateSellerLink: "/sellers/new",
                      addPrivateBuyerLink: "/private_buyers/new",
                      addBroadcastBuyerLink: "/client_needs/new",
                      addBroadcastSellerLink: "/properties/new",
                      addBroadcastMessageLink: "/messages/new"
                    })
              ]
            });
}

var responsive = Responsive.make;

var make$1 = DashboardHero;

exports.responsive = responsive;
exports.DashboardHeroUI = DashboardHeroUI;
exports.make = make$1;
/* make Not a pure module */
