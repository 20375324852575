import styled from 'styled-components'
import BaseTouchable from 'uikit/helpers/TouchableView'
import BaseViewLink from '../../components/ViewLink'

const hoverStyles = `
@media (hover: hover) {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0), 0 5px 10px rgba(0, 0, 0, 0);
  transition: all 0.15s ease;
  will-change: transform;
  &:focus {
    box-shadow: 0 3px 10px rgba(27, 53, 73, 0.15);, 0 8px 25px rgba(27, 53, 73, 0.07);
  }
  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 8px 15px rgba(0, 0, 0, 0.03);
    &:active {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06), 0 4px 10px rgba(0, 0, 0, 0.05);
    }
  }
}
`

export const TouchableView = styled(BaseTouchable)`
  ${hoverStyles};
`
export const ViewLink = styled(BaseViewLink)`
  ${hoverStyles};
`
export const BottomShadow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.075));
`
export const ClickOutsideOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  cursor: initial;
`
